/* eslint-disable cds/no-next-imports */
import {
  forwardRef,
  InputHTMLAttributes,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import NextLink from 'next/link';
import { VariantProps, styled, CSSProps, AsProps, css } from '@neui/core';
import { Icon, IconNames, Typography } from '@neui/styleguide-commerzbank';

import { useMakeLink } from '@components/Link';
import { makeLinkTargetProps } from '@utils/helpers/linkHelpers';

const buttonStyle = css({
  '$$button-padding-x': '$space$component-7',
  '$$button-padding-y': '$space$component-4',
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  minWidth: 120,
  cursor: 'pointer',
  padding: '$$button-padding-y $$button-padding-x',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  gap: '$component-3',
  zIndex: 0,
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    zIndex: -1,
    borderRadius: 100,
  },
  '&:focus-visible:not([disabled])': {
    outline: '2px solid $text-standard',
    border: 'none',
    borderRadius: 30,
    outlineOffset: 4,
  },
  '@sm': {
    width: 'fit-content',
  },
  variants: {
    type: {
      primary: {
        [`& > *`]: {
          color: '$text-button !important',
        },
        zIndex: 1,
        '&::before': {
          backgroundColor: '$primary',
          transform: 'scale(1.0)',
          transition:
            'background-color 100ms ease-in, transform 100ms ease-out',
        },
        '&:hover': {
          '&::before': {
            backgroundColor: '$primary-hover',
          },
        },
        '&:active': {
          '&::before': {
            transform: 'scale(0.9)',
          },
        },
      },
      secondary: {
        [`& > *`]: {
          color: '$text-standard !important',
        },
        '&::before': {
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: '$secondary',
          transition: 'outline-width 100ms ease-in',
        },
        '&:hover, &:focus': {
          '&::before': {
            borderWidth: 2,
            borderColor: '$secondary-hover',
          },
        },
        '&:active': {
          '&::before': {
            borderWidth: 3,
            borderColor: '$secondary-hover',
          },
        },
      },
      tertiary: {
        [`& > *`]: {
          color: '$text-button-inverse !important',
        },
        zIndex: 1,
        '&::before': {
          backgroundColor: '$tertiary',
          transform: 'scale(1.0)',
          transition:
            'background-color 100ms ease-in, transform 100ms ease-out',
        },
        '&:hover, &:focus': {
          '&::before': {
            backgroundColor: '$tertiary-hover',
          },
        },
        '&:active': {
          '&::before': {
            transform: 'scale(0.9)',
          },
        },
      },
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        '& > *': {
          color: '$text-disabled !important',
        },
        '&::before': {
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: '$disabled',
        },
      },
      false: {},
    },
  },
});

const StyledIcon = styled(Icon, {
  variants: {
    disabled: {
      true: {
        '& svg': {
          fill: '$text-disabled',
        },
      },
    },
  },
});

const StyledAnchor = styled('a', buttonStyle);
const StyledButton = styled('button', buttonStyle);

export type ButtonProps = CSSProps &
  AsProps &
  VariantProps<typeof buttonStyle> &
  InputHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    href?: string;
    onClick?: (e?: MouseEvent) => void;
    icon?: IconNames;
  }>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      href: inboundHref,
      renderAs: StyledComponent = inboundHref
        ? StyledAnchor
        : (StyledButton as React.ElementType),
      type = 'primary',
      disabled = false,
      children,
      icon,
      ...rest
    },
    forwardedRef,
  ) => {
    const makeLink = useMakeLink();
    const href = makeLink({ href: inboundHref });
    const linkTargetProps = makeLinkTargetProps(href);

    const content = (
      <StyledComponent
        {...linkTargetProps}
        {...rest}
        ref={forwardedRef}
        disabled={disabled}
        type={type}
        href={href}
        css={!children ? { gap: 0 } : undefined}
      >
        <Typography renderAs="span" size={6} weight="medium">
          {children}
        </Typography>
        {icon && <StyledIcon icon={icon} size="small" disabled={disabled} />}
      </StyledComponent>
    );

    return href !== undefined ? (
      <NextLink href={href} passHref>
        {content}
      </NextLink>
    ) : (
      <>{content}</>
    );
  },
);
Button.displayName = 'Button';
