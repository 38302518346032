/* eslint-disable react-hooks/exhaustive-deps */
import {
  interaction___close,
  interaction_arrows_arrowRight,
  Typography,
} from '@neui/styleguide-commerzbank';
import { useRouter } from 'next/router';
import React from 'react';
import { styled } from '@neui/core';
import { Box, VStack } from '@neui/layout';

import {
  createPortalEntity,
  createSearchEntity,
  useTracker,
} from '@utils/snowplowTracking';
import { Breadcrumbs } from '@components/neui-components/molecules/Breadcrumbs';
import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import { SearchCtx } from '@components/Search/CdsSearch';
import { $t } from '@utils/i18n';
import { useMakeLink } from '@components/Link';
import { getSearchParameters, shorten } from '@components/Search/helpers';
import { BreadcrumbsItem } from '@components/neui-components/atoms/BreadcrumbsItem';
import { BannerTextBadge } from '@components/neui-components/atoms/BannerTextBadge';
import { BannerTextBadgeProps } from '@components/neui-components/atoms/BannerTextBadge';
import { XColumnsGridItem } from 'page-templates/XColumnsGridItem';
import { MostReadArticleType } from 'pages';
import SearchInteractionIcon from '@components/icons/SearchInteractionIcon';
import { Underline } from '@components/neui-components/atoms/Underline';
import {
  MarginTopTypography,
  NoResultSpacing,
  StyledBox,
} from '@components/CdsStyledComponents';
import ErrorIcon from '@components/icons/ErrorIcon';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { isInlineWidget, isTopWidget } from '@utils/WidgetChecker';
import { InlineWidgetRenderer } from '@components/Widgets/InlineWIdgetRenderer';
import { Link } from '@components/Link';
import { isGpp, isGppDE } from '@utils/DataFetcher';
import {
  alternativePortalsEnglish,
  alternativePortalsGerman,
} from '@utils/alternativePortals';
import { ThumbnailWrapper } from '@components/neui-components/molecules/CircleIconLink';
import { Icon } from '@components/neui-components/atoms/Icon';
import {
  defaultAttrs,
  nachKonBadgeRules,
  nachKonMostSearchedTerms,
  pukBadgeRules,
} from '@utils/searchResults';
import {
  GA4SearchType,
  GA4TrackInternalSearch,
  GA4TrackSearchResultClick,
} from '@utils/tracking';

import { WidgetRenderer } from '../Widgets/WidgetRenderer';
import { SuggestionResultType } from '.';
import { SuggestionChips } from './SuggestionChips';

interface SearchResultsWrapperProps {
  totalHits?: number;
  searchResults: Array<SuggestionResultType>;
  typoCorrection?: string;
  searchResultsTitle: string;
  mostSearchedTerms?: string[];
  mostReadArticles: MostReadArticleType[];
  isNachKon?: boolean;
  isNachKonDe?: boolean;
  nachKonPortalUrl: string;
  page: number;
  latestSuggestion: string;
  isSearchDown: boolean;
  executeSearch: (
    query: string,
    page: number,
    correctTypos: boolean,
    searchType: GA4SearchType,
  ) => Promise<void>;
  firstItemRef?: React.Ref<HTMLDivElement>;
}

export const CdsSearchResultsWrapper = ({
  totalHits,
  searchResults,
  typoCorrection,
  searchResultsTitle,
  mostSearchedTerms,
  mostReadArticles,
  isNachKon,
  isNachKonDe,
  nachKonPortalUrl,
  page,
  latestSuggestion,
  isSearchDown,
  executeSearch,
  firstItemRef,
}: SearchResultsWrapperProps): JSX.Element => {
  const { trackButtonClick, trackSearch } = useTracker(
    CdsSearchResultsWrapper.name,
  );
  const router = useRouter();
  const makeLink = useMakeLink();
  const { searchType } = getSearchParameters(router);
  const areThereResults = searchResults.length > 0;

  const isNachKonEn = isNachKon && !isNachKonDe;
  const isPuk = !isGpp && !isNachKon;

  function getBadgeAttributes(
    href: string,
    isNachKon: boolean,
  ): BannerTextBadgeProps {
    const badgeRules = !isNachKon ? pukBadgeRules : nachKonBadgeRules;

    return (
      badgeRules.find((item) => item.rules.find((rule) => href.match(rule)))
        ?.attrs ?? defaultAttrs(isNachKonDe ?? false)
    );
  }

  const topArticles = !isNachKon ? mostReadArticles : undefined;

  let resultText;

  if (isSearchDown) {
    // case 5: Error message when search is down
    resultText = (
      <VStack
        flexDirection={{ base: 'column', md: 'row-reverse' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingBottom={{ base: '$layout-2', md: '$layout-3' }}
      >
        <Box>
          <ErrorIcon />
        </Box>
        <StyledBox>
          <VStack
            paddingBottom={4}
            marginTop={{ base: 0, md: '$layout-5' }}
            marginBottom={{ base: 0, md: '$layout-5' }}
          >
            <Typography
              size={4}
              weight={'medium'}
              renderAs={'h1'}
              css={{ marginBottom: '4px' }}
            >
              {isNachKonEn
                ? 'The search functionality is temporarily offline.'
                : $t('SEARCH_IS_DOWN')}
            </Typography>
            <Typography size={7} weight={'book'} css={{ marginBottom: '16px' }}>
              {isNachKonEn
                ? 'We are already working on fixing the issue. Please try again later.  Support articles are still available on the Serviceportal.'
                : $t('SEARCH_IS_DOWN_SUBLINE')}
            </Typography>
            <IconLink
              iconPosition="right"
              icon={interaction_arrows_arrowRight}
              data-cy={'top-article'}
              href="/"
            >
              Zum Serviceportal
            </IconLink>
          </VStack>
        </StyledBox>
      </VStack>
    );
  } else if (areThereResults) {
    const enoughResults = totalHits !== undefined && totalHits > 5;
    // cases when there is typo correction suggestion
    if (typoCorrection !== undefined) {
      resultText = (
        <VStack paddingBottom={{ base: 24, md: 32 }}>
          {/* case 2: immediate typo correction*/}
          {typoCorrection !== searchResultsTitle && enoughResults && (
            <>
              <Typography
                size={4}
                weight={'medium'}
                renderAs={'h1'}
                css={{ marginBottom: '4px' }}
              >
                {totalHits}{' '}
                {isNachKonEn ? 'Search results for ' : $t('SEARCH_RESULTS_FOR')}{' '}
                &ldquo;
                {typoCorrection}
                &rdquo;
              </Typography>
              <ResultTitleTypography size={7} weight={'book'}>
                {isNachKonEn ? 'Search instead for ' : $t('SEARCH_INSTEAD_FOR')}
                &ldquo;
                <Link
                  underlined={true}
                  renderAs={'button'}
                  weight={'medium'}
                  size={7}
                  onClick={() => {
                    const searchType = 'Unknown';
                    isPuk &&
                      GA4TrackInternalSearch(
                        searchResultsTitle ?? '',
                        searchType,
                      );
                    executeSearch(searchResultsTitle, 1, false, searchType);
                    trackButtonClick?.(searchResultsTitle, 'search_term', []);
                  }}
                >
                  {searchResultsTitle}
                </Link>
                &rdquo;
              </ResultTitleTypography>
            </>
          )}
          {/* case 6: original query doesnt have many results so typo correction is suggested as an option*/}
          {!enoughResults && typoCorrection !== searchResultsTitle && (
            <>
              <Typography
                size={4}
                weight={'medium'}
                renderAs={'h1'}
                css={{ marginBottom: '4px' }}
              >
                {totalHits}{' '}
                {isNachKonEn ? 'Search results for ' : $t('SEARCH_RESULTS_FOR')}
                &ldquo;
                {searchResultsTitle}
                &rdquo;
              </Typography>
              <ResultTitleTypography size={7} weight={'book'}>
                {isNachKonEn ? 'Did you mean ' : $t('CDS_SEARCH_NOT_FOUND_2')}
                <Link
                  underlined={true}
                  renderAs={'button'}
                  weight={'medium'}
                  size={7}
                  onClick={() => {
                    const searchType = 'Typo suggestion';
                    isPuk &&
                      GA4TrackInternalSearch(typoCorrection ?? '', searchType);
                    executeSearch(typoCorrection ?? '', 1, false, searchType);
                    trackButtonClick?.(typoCorrection ?? '', 'search_term', []);
                  }}
                >
                  {typoCorrection}
                </Link>
                &rdquo;
              </ResultTitleTypography>
            </>
          )}
        </VStack>
      );
    } else {
      // case 1: default - normal search results
      resultText = (
        <VStack
          flexDirection={'row'}
          justifyContent={'space-between'}
          paddingBottom={{ base: 24, md: 32 }}
        >
          <Typography size={4} weight={'medium'} renderAs={'h1'}>
            {totalHits}{' '}
            {isNachKonEn ? 'Search results for ' : $t('SEARCH_RESULTS_FOR')}
            &ldquo;
            {searchResultsTitle}
            &rdquo;
          </Typography>
          {isNachKon && (
            <StyledIconLink
              iconPosition="right"
              icon={interaction___close}
              animationDirection="left"
              href={nachKonPortalUrl ?? ''}
            >
              {isNachKonEn ? 'close search' : $t('SEARCH_CLOSE')}
            </StyledIconLink>
          )}
        </VStack>
      );
    }
  } else {
    // case 3 and 4: no search results
    resultText = (
      <>
        <VStack
          flexDirection={{ base: 'column', md: 'row-reverse' }}
          justifyContent={'space-between'}
          paddingBottom={{ base: '$layout-2', md: '$layout-3' }}
        >
          <VStack alignItems={'center'}>
            <SearchInteractionIcon />
          </VStack>
          <VStack
            paddingBottom={4}
            marginTop={{ base: 0, md: '$layout-5' }}
            marginBottom={{ base: 0, md: '$layout-5' }}
          >
            <Typography
              size={4}
              weight={'medium'}
              renderAs={'h1'}
              textAlign={'left'}
            >
              {isNachKonEn ? '0 results for ' : $t('CDS_SEARCH_NOT_FOUND_1')}
              &ldquo;
              {searchResultsTitle}
              &rdquo;
            </Typography>
            <VStack>
              <Typography size={7} weight={'book'}>
                {latestSuggestion.length > 0 ? (
                  <>
                    {/* case 3: last suggestion is offered as an option */}
                    {isNachKonEn
                      ? 'Did you mean '
                      : $t('CDS_SEARCH_NOT_FOUND_2')}
                    &ldquo;
                    <Link
                      renderAs={'button'}
                      underlined={true}
                      weight={'medium'}
                      size={7}
                      onClick={() => {
                        isPuk &&
                          GA4TrackInternalSearch(
                            latestSuggestion,
                            'Typo suggestion',
                            1,
                          );
                        executeSearch(
                          latestSuggestion,
                          1,
                          false,
                          'Search suggestion',
                        );
                        trackButtonClick?.(latestSuggestion, 'search_term', []);
                      }}
                    >
                      {latestSuggestion}
                    </Link>
                    &rdquo; ?
                  </>
                ) : isNachKonEn ? (
                  // case 4: no last sugg to offer
                  'Try a different search term or check out our suggestions below.'
                ) : (
                  $t('CDS_SEARCH_NOT_FOUND_3')
                )}
              </Typography>
            </VStack>
          </VStack>
        </VStack>
        <VStack
          role="separator"
          marginBottom={{ base: '$layout-2', md: '$layout-3' }}
          marginTop={{ base: '$layout-2', md: 0 }}
        >
          <Underline disabled css={{ width: '100%' }} />
        </VStack>
        <VStack spacing={{ base: '$component-2', md: '$layout-1' }}>
          <Typography size={7} weight={'book'} color={'helper'}>
            {isPuk || isGppDE || isNachKonDe
              ? 'Häufige Suchanfragen'
              : 'Commonly searched'}
          </Typography>
          <SuggestionChips
            mostSearchedTerms={
              nachKonMostSearchedTerms(
                isNachKon ?? false,
                isNachKonDe ?? false,
              ) ?? mostSearchedTerms
            }
            executeSearch={(query, searchType) =>
              executeSearch(query, 1, false, searchType)
            }
          />

          <MarginTopTypography size={7} weight={'book'} color={'helper'}>
            {!isNachKon &&
              (isPuk || isGppDE ? 'Häufige Fragen' : 'Commonly asked')}
          </MarginTopTypography>
          {topArticles !== undefined && (
            <VStack>
              {topArticles.map((article, index: number) => {
                return (
                  <ClickItem key={article.title + index} href={article.slug}>
                    <StyledVStack css={{ zIndex: 1, marginY: 12 }}>
                      <Typography size={6} weight="medium">
                        {article.title}
                      </Typography>
                      <Typography size={7} weight={'book'} color={'helper'}>
                        {article.excerpt}
                      </Typography>
                    </StyledVStack>
                  </ClickItem>
                );
              })}
            </VStack>
          )}
        </VStack>
      </>
    );
  }
  let topWidgets = null;
  const result = searchResults[0];
  const name = result?.name;
  const widgetsToRender = result?.widgetsToRender;
  const doesTopWidgetHaveIcon = result?.widget?.ctas[0].icon;
  const hasTopWidgets =
    areThereResults &&
    widgetsToRender.length > 0 &&
    isTopWidget(widgetsToRender) &&
    doesTopWidgetHaveIcon;

  if (hasTopWidgets) {
    const searchContext = createSearchEntity(
      searchResultsTitle,
      name,
      'result',
      page ?? null,
      searchResults,
      undefined, //results dont have suggestions
      widgetsToRender,
    );
    topWidgets = (
      <SearchCtx.Provider value={searchContext}>
        <WidgetRenderer
          ctas={result?.widget?.ctas ?? []}
          widgetsToRender={widgetsToRender}
        />
      </SearchCtx.Provider>
    );
  }

  const alternativePortals = isNachKonDe
    ? alternativePortalsGerman
    : alternativePortalsEnglish;

  const firstHalf = searchResults.slice(0, 5);
  const secondHalf = searchResults.slice(5, 10);

  return (
    <VStack alignItems={'center'}>
      <XColumnsGridItem columns={{ base: 12, md: 8 }}>
        <NoResultSpacing areThereResults={areThereResults}>
          {!isNachKon && (
            // the ref is for an accessibility workaround
            <Breadcrumbs label={$t('HELP')} ref={firstItemRef}>
              <BreadcrumbsItem
                target="/"
                //yes, it looks dumb, but in the future the english version should be Service portal
                label={isGpp ? $t('HOME_GPP') : $t('HOME')}
              />
              <BreadcrumbsItem
                target=""
                label={$t('SEARCH_RESULTS')}
                disabled
              />
            </Breadcrumbs>
          )}
          <VStack css={{ width: '100%' }}>
            {resultText}
            {topWidgets}

            <VStack>
              {firstHalf &&
                firstHalf.map((result, index) => {
                  const name = result?.name;
                  const widgetsToRender = result?.widgetsToRender;
                  const portalContext = createPortalEntity(
                    result.category ?? '',
                    result.name,
                  );

                  const searchContext = createSearchEntity(
                    searchResultsTitle,
                    name,
                    'result',
                    page ?? null,
                    searchResults,
                    undefined, //results dont have suggestions
                    widgetsToRender,
                  );

                  const href =
                    makeLink({
                      href: result?.slug,
                      absoluteUrl: true,
                    }) ?? '';

                  const atts: BannerTextBadgeProps = getBadgeAttributes(
                    href,
                    isNachKon ?? false,
                  );
                  const hasWidget =
                    (index > 0 || !hasTopWidgets) &&
                    widgetsToRender.length > 0 &&
                    isInlineWidget(widgetsToRender);
                  // const hasChatbotMsg = index === 4;

                  return (
                    <React.Fragment key={index}>
                      <StyledClickItem
                        href={href}
                        hasWidget={hasWidget || (hasWidget && !isNachKon)}
                        //remove last borderbottom when nachkon
                        css={{
                          '& > div': {
                            borderBottom: isNachKon
                              ? undefined
                              : '1px solid $petrol200',
                          },
                        }}
                        onClick={() => {
                          trackSearch?.('result_click', [
                            searchContext,
                            portalContext,
                          ]);
                          isPuk &&
                            GA4TrackSearchResultClick(
                              searchResultsTitle,
                              name,
                              makeLink({ href }),
                              index + 1 + (page - 1) * 10,
                              searchType,
                              totalHits ?? 0,
                              page,
                              atts.title || '',
                            );
                        }}
                      >
                        <VStack css={{ zIndex: 1 }}>
                          <VStack spacing={8}>
                            <Box>
                              <BannerTextBadge look={atts.look}>
                                {atts.title}
                              </BannerTextBadge>
                            </Box>
                            <StyledTypography size={6} weight="medium">
                              {name}
                            </StyledTypography>
                          </VStack>
                          <Typography size={7} color="helper" weight={'book'}>
                            {shorten(result.description ?? '', 160)}
                          </Typography>
                        </VStack>
                      </StyledClickItem>
                      {hasWidget && (
                        <SearchCtx.Provider value={searchContext}>
                          <InlineWidgetRenderer
                            ctas={result?.widget?.ctas ?? []}
                            widgetsToRender={widgetsToRender}
                          />
                        </SearchCtx.Provider>
                      )}
                    </React.Fragment>
                  );
                })}
            </VStack>

            {isNachKon && (
              <VStack
                marginTop={{ base: '$layout-2', md: '$layout-3' }}
                marginBottom={{ base: '$layout-2', md: '$layout-3' }}
              >
                <StyledTypography
                  size={5}
                  weight="medium"
                  css={{ paddingBottom: '$component-4' }}
                >
                  {isNachKonEn
                    ? "Didn't find what you were looking for? Switch to topic page:"
                    : $t('SEARCH_GO_TO_PORTAL')}
                </StyledTypography>
                {alternativePortals.map((item, idx) => {
                  return (
                    <ClickItem key={idx} href={item.url}>
                      <VStack
                        flexDirection={'row'}
                        alignItems={'center'}
                        spacing={'$component-4'}
                      >
                        <ThumbnailWrapper
                          alignItems="center"
                          justifyContent="center"
                          look={'secondary'}
                        >
                          <Icon
                            icon={item.icon}
                            variant={'outline'}
                            size="small"
                          />
                        </ThumbnailWrapper>
                        <Typography size={6} weight="medium">
                          {item.text}
                        </Typography>
                      </VStack>
                    </ClickItem>
                  );
                })}
              </VStack>
            )}
            <VStack>
              {secondHalf &&
                secondHalf.map((result, index) => {
                  const name = result?.name;
                  const widgetsToRender = result?.widgetsToRender;
                  const portalContext = createPortalEntity(
                    result.category ?? '',
                    result.name,
                  );

                  const searchContext = createSearchEntity(
                    searchResultsTitle,
                    name,
                    'result',
                    page ?? null,
                    searchResults,
                    undefined, //results dont have suggestions
                    widgetsToRender,
                  );

                  const href =
                    makeLink({
                      href: result?.slug,
                      absoluteUrl: true,
                    }) ?? '';

                  const atts: BannerTextBadgeProps = getBadgeAttributes(
                    href,
                    isNachKon ?? false,
                  );

                  return (
                    <React.Fragment key={index}>
                      <ClickItem
                        key={index}
                        href={href}
                        onClick={() => {
                          trackSearch?.('result_click', [
                            searchContext,
                            portalContext,
                          ]);
                          isPuk &&
                            GA4TrackSearchResultClick(
                              searchResultsTitle,
                              name,
                              makeLink({ href }),
                              index + 6 + (page - 1) * 10,
                              searchType,
                              totalHits ?? 0,
                              page,
                              atts.title || '',
                            );
                        }}
                      >
                        <VStack css={{ zIndex: 1 }}>
                          <VStack spacing={8}>
                            <Box>
                              <BannerTextBadge look={atts.look}>
                                {atts.title}
                              </BannerTextBadge>
                            </Box>
                            <StyledTypography size={6} weight="medium">
                              {name}
                            </StyledTypography>
                          </VStack>
                          <Typography size={7} color="helper" weight={'book'}>
                            {shorten(result.description ?? '', 160)}
                          </Typography>
                        </VStack>
                      </ClickItem>
                    </React.Fragment>
                  );
                })}
            </VStack>
          </VStack>
        </NoResultSpacing>
      </XColumnsGridItem>
    </VStack>
  );
};

const StyledTypography = styled(Typography, {
  position: 'relative',
  zIndex: 1,
});

const StyledClickItem = styled(ClickItem, {
  variants: {
    hasWidget: {
      true: {
        '& > div': {
          borderBottom: 'none !important',
        },
      },
      false: {},
    },
  },
});

const StyledVStack = styled(VStack, {
  zIndex: 1,
  marginY: 12,
});

const ResultTitleTypography = styled(Typography, {
  marginBottom: 0,
  md: {
    marginBottom: '16px',
  },
});

const StyledIconLink = styled(IconLink, {
  display: 'none',
  '@sm': {
    display: 'flex',
  },
});
