import { QRCode, IProps } from 'react-qrcode-logo';
import React, { useContext } from 'react';
import { styled } from '@neui/core';
import { Typography } from '@neui/styleguide-commerzbank';
import { HStack, VStack } from '@neui/layout';

import { SearchCtx } from '@components/Search/CdsSearch';
import { WidgetName } from '@utils/snowplowTracking';
import { useMakeLink } from '@components/Link';

type QrCodeProps = IProps & {
  url: string;
  label?: string;
  widgetName: WidgetName;
};

export const QrCodeWidget = ({
  url,
  label,
  widgetName,
  ...rest
}: QrCodeProps): JSX.Element => {
  const searchContext = useContext(SearchCtx);
  const makeLink = useMakeLink();

  if (!url) {
    return <></>;
  }

  const query = searchContext?.data.search_text;
  const q = query?.replace(/\s/g, '_');
  const result = searchContext?.data.results.search_results.result_1;
  const r = result?.replace(/\s/g, '_');
  const redirectUrl = makeLink({
    href: `/widget?name=${widgetName}&url=${url}&q=${q}&r=${r}`,
    absoluteUrl: true,
  });

  return (
    <>
      <QrContainer spacing={16}>
        <WidgetContainer spacing={12}>
          <QRCode
            size={112}
            value={redirectUrl}
            fgColor={'#103D4B'}
            qrStyle="dots"
            logoPadding={1}
            {...rest}
          />
          {label && (
            <Typography size={7} weight={'medium'} textAlign={'center'}>
              {label}
            </Typography>
          )}
        </WidgetContainer>
      </QrContainer>
    </>
  );
};

const QrContainer = styled(HStack, {
  alignItems: 'flex-start',
});

const WidgetContainer = styled(VStack, {
  alignItems: 'center',
});
