import de from './de.json';
import en from './en.json';

export type LanguageType = 'de' | 'en';

// default language is german
export const currentLanguage = process.env
  .NEXT_PUBLIC_SITE_LANGUAGE as LanguageType;

const currentJson = currentLanguage === 'en' ? en : de;

export const $t = (slug: keyof typeof currentJson): string => currentJson[slug];

export const languageDomains: { [Property in LanguageType]: string } = {
  de: process.env.NEXT_PUBLIC_SITE_LANGUAGE_DOMAINS_DE ?? '',
  en: process.env.NEXT_PUBLIC_SITE_LANGUAGE_DOMAINS_EN ?? '',
};
