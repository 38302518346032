import { keyframes, styled } from '@neui/core';
import { VStack } from '@neui/layout';
import { useEffect, useRef, useState } from 'react';

import { Api } from '../../chatbot-api';
import { ChatHeader } from './ChatHeader';
import { ScreenStateRenderer } from './ScreenStateRenderer';
import { selectChatProps, useChatStore } from './store/store';

export const apiClient = new Api({
  baseUrl: process.env.NEXT_PUBLIC_OB_HOST ?? 'https://kunden.commerzbank.de',
});

type ChatWindowProps = {
  isChatMinimized: boolean;
};

export function ChatWindow({ isChatMinimized }: ChatWindowProps) {
  const chatWindow = useRef<HTMLDivElement>(null);
  const [hasFocused, setHasFocused] = useState(false);

  const { userState } = useChatStore(selectChatProps);
  useEffect(() => {
    const chatContainer = chatWindow.current;

    if (chatContainer && !isChatMinimized) {
      const focusableElements = chatContainer.querySelectorAll(
        'button, textarea, [tabindex]:not([tabindex="-1"])',
      );

      if (focusableElements.length > 0) {
        const firstElement = focusableElements[0] as HTMLElement;
        const lastElement = focusableElements[
          focusableElements.length - 1
        ] as HTMLElement;
        const textareaElement = chatContainer.querySelector(
          'textarea',
        ) as HTMLElement | null;

        if (
          !hasFocused ||
          (userState.status === 'online' && !userState.hasConsented)
        ) {
          firstElement.focus();
          setHasFocused(true);
        } else {
          textareaElement?.focus();
        }

        const handleTabKeyPress = (e: KeyboardEvent) => {
          if (e.code === 'Tab') {
            if (e.shiftKey && document.activeElement === firstElement) {
              e.preventDefault();
              lastElement.focus();
            } else if (!e.shiftKey && document.activeElement === lastElement) {
              e.preventDefault();
              firstElement.focus();
            }
          }
        };

        chatContainer.addEventListener('keydown', handleTabKeyPress);

        return () => {
          chatContainer.removeEventListener('keydown', handleTabKeyPress);
        };
      }
    }
  }, [isChatMinimized, hasFocused, userState]);

  return (
    <Wrapper ref={chatWindow} state={isChatMinimized ? 'closing' : 'opening'}>
      <ChatHeader />
      <ScreenStateRenderer />
    </Wrapper>
  );
}

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.6)',
    transformOrigin: 'bottom right',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1)',
    transformOrigin: 'bottom right',
  },
});

const fadeOut = keyframes({
  '0%': { opacity: 1, transform: 'scale(1)' },
  '99%': { opacity: 0, transform: 'scale(0.6)' },
  '100%': { display: 'none !important' },
});

const Wrapper = styled(VStack, {
  display: 'none',
  zIndex: 1338,
  width: '100%',
  height: '100%',
  position: 'fixed',
  right: 0,
  bottom: 0,
  backgroundColor: '$colors$neutral0',
  boxShadow: '0 6px 36px -8px rgb(0 0 0 / 36%)',
  overflow: 'hidden',
  '@sm': {
    borderRadius: 16,
    minHeight: 400,
    width: 400,
    right: 16,
    bottom: 32,
    height: 628,
    maxHeight: 628,
  },
  variants: {
    state: {
      opening: {
        display: 'flex',
        animation: `${fadeIn} 240ms cubic-bezier(0.34, 0.63, 0, 1)`,
      },
      closing: {
        animation: `${fadeOut} 240ms cubic-bezier(0.34, 0.63, 0, 1)`,
      },
    },
  },
});
