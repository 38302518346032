import { styled } from '@neui/core';
import { HStack, AspectRatio } from '@neui/layout';
import {
  interaction_arrows_chevronDown,
  // object_devices_printer,
} from '@neui/styleguide-commerzbank';

import { IconLink } from '@components/neui-components/atoms/IconLink';
import { Image } from '@components/Image';

import { selectChatActions, useChatStore } from './store/store';
// import jsPDF from 'jspdf';
// import { apiClient } from './ChatWindow';
// import { formatMsgTime } from './utils/utils';

export function ChatHeader() {
  const { setIsChatMinimized } = useChatStore(selectChatActions);
  return (
    <Header alignItems={'center'} spacing={32} justifyContent={'space-between'}>
      <HStack alignItems={'center'}>
        <AspectRatio aspectRatio="1" size={72}>
          <Image src={'/service/icons/chatbot-icon.svg'} alt="" />
        </AspectRatio>
      </HStack>
      <HStack spacing={16}>
        {/* {userState.hasConsented && userState.state !== 4 && (
          <IconLink
            icon={object_devices_printer}
            animationDirection="none"
            onClick={async () => {
              const res = await apiClient.chat.messageHistoryUsingPost(
                {
                  participantId: userState.participantId,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    pToken: window?.ccb_cif?.pToken ?? '',
                  },
                }
              );

              const doc = new jsPDF();
              const pageHeight = doc.internal.pageSize.height;
              // const pageWidth = doc.internal.pageSize.width; // 210
              doc.setFontSize(14);
              const margin = 15;
              const defaultYJump = 5;
              let i = 1;

              const msgs = res.data.result?.data?.messages;
              msgs?.forEach((msg) => {
                if (msg.value === undefined || msg.value.startsWith('{')) {
                  return '';
                }
                let posY = margin + defaultYJump * i++;
                if (posY > pageHeight - margin) {
                  doc.addPage();
                  i = 1;
                  posY = margin + defaultYJump * i++;
                }
                const date = new Date(msg.timestamp);
                const formattedDate = date.toLocaleDateString();
                const time = formatMsgTime(msg.timestamp);
                const displayName = agentState.agents[msg.participantId];
                const txt = `${formattedDate} - ${time} - ${displayName}: ${msg.value}`;
                doc.text(txt, 15, posY);
              });
              // doc.text(pdfText, 24, 24);
              doc.autoPrint();
              doc.output('dataurlnewwindow');
            }}
          />
        )} */}
        <IconLink
          iconTitle="Chatfenster einklappen"
          aria-label="Chatfenster einklappen"
          icon={interaction_arrows_chevronDown}
          animationDirection="none"
          onClick={() => setIsChatMinimized(true)}
        />
      </HStack>
    </Header>
  );
}

const Header = styled(HStack, {
  position: 'relative',
  width: '100%',
  height: 'auto',
  padding: '4px 24px 4px 4px',
  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.36)',
  zIndex: 10,
  top: 0,
  flexGrow: 0,
});
