/* eslint-disable react/no-multi-comp */
import React from 'react';
import { styled } from '@neui/core';
import { Flex, HStack, VStack } from '@neui/layout';
import { Headline, Typography } from '@neui/styleguide-commerzbank';

import { WIDGET_TYPES } from '@utils/WidgetChecker';
import { WidgetName, widgetToTrackType } from '@utils/snowplowTracking';
import { QrCodeWidget } from '@components/Widgets/QrCodeWidget';
import { Image } from '@components/Image';

import { WidgetActionButton } from './WidgetActionButton';
import { ICallToAction } from './types';

export const MobileAppAndroid = ({
  ctas,
  widgetName,
}: {
  ctas: ICallToAction[];
  widgetName: WidgetName;
}): JSX.Element => {
  return (
    <>
      {ctas
        ?.filter((cta) => cta.category === 'android application')
        .map((cta, idx) => (
          <WidgetActionButton
            key={idx}
            url={cta.url}
            label={'PhotoTAN App im Google Play Store'}
            icon={'brands___google'}
            widgetName={widgetName}
            variant="solid"
          />
        ))}
    </>
  );
};

export const MobileAppIos = ({
  ctas,
  widgetName,
}: {
  ctas: ICallToAction[];
  widgetName: WidgetName;
}): JSX.Element => {
  return (
    <>
      {ctas
        ?.filter((cta) => cta.category === 'ios application')
        .map((cta, idx) => (
          <WidgetActionButton
            key={idx}
            url={cta.url}
            label={'PhotoTAN App im iOS Appstore'}
            icon={'brands___apple'}
            widgetName={widgetName}
            variant="solid"
          />
        ))}
    </>
  );
};

export const MobileAppDesktop = ({
  ctas,
}: {
  ctas: Array<ICallToAction>;
}): JSX.Element => {
  const androidCta = ctas.filter((x) => x.category === 'android application');
  const iosCta = ctas.filter((x) => x.category === 'ios application');

  return (
    <>
      <HStack spacing={32}>
        <VStack spacing={16}>
          <Headline type={'h5'} renderAs="span">
            photoTAN App herunterladen
          </Headline>
          <Typography>
            Scannen Sie den QR-Code, um die PhotoTAN App im Appstore oder Google
            Play Store herunterzuladen.
          </Typography>
          <Wrapper>
            <QrCodeWidget
              url={iosCta[0].url ?? ''}
              label={iosCta[0].cta}
              widgetName={widgetToTrackType[WIDGET_TYPES.MOBILEAPPIOS]}
              logoImage={'./icons/apple-logo.svg'}
            />
            <QrCodeWidget
              url={androidCta[0].url ?? ''}
              label={androidCta[0].cta}
              widgetName={widgetToTrackType[WIDGET_TYPES.MOBILEAPPANDROID]}
              logoImage={'./icons/google-logo.svg'}
            />
          </Wrapper>
        </VStack>
        <Image src="/icons/phototan-illustration.svg" alt="" />
      </HStack>
    </>
  );
};

const Wrapper = styled(Flex, {
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '24px 32px',
  flexWrap: 'wrap',
  textAlign: 'center',
});
