import { AspectRatio } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';

import { Image } from '@components/Image';
import { Link } from '@components/neui-components/atoms/Link';

import ChainIcon from './chain-icon';

type StructuredButtonMessageProps = {
  image?: string;
  title?: string;
  component: {
    actions: {
      url: string;
      urlTarget: '_blank' | '_self';
    };
    id: string;
    text: string;
    type: string; // button
  };
};
export function StructuredButtonMessage({
  image,
  title,
  component,
}: StructuredButtonMessageProps) {
  return (
    <>
      {image && (
        <AspectRatio
          aspectRatio={'16 / 9'}
          onClick={() =>
            window.open(component.actions.url, component.actions.urlTarget)
          }
        >
          {<Image src={image} alt={title} />}
        </AspectRatio>
      )}
      <StyledLink
        href={component.actions.url}
        target={component.actions.urlTarget}
      >
        <Headline type={'h6'} css={{ width: 224 }}>
          {component.text}
        </Headline>
        <ChainIcon />
      </StyledLink>
    </>
  );
}

const StyledLink = styled(Link, {
  display: 'block',
  padding: 16,
  '& > span': {
    display: 'flex !important',
    alignItems: 'center',
    gap: 24,
  },
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    borderRadius: 8,
  },
});
