import React from 'react';

import { WidgetName } from '@utils/snowplowTracking';
import { isOnlineBanking } from '@utils/WidgetChecker';

import { ICallToAction } from './types';
import { WidgetActionButton } from './WidgetActionButton';

const OnlineBankWidget = ({
  ctas,
  widgetName,
}: {
  ctas: Array<ICallToAction>;
  widgetName: WidgetName;
}): JSX.Element => {
  const onlineBanking = ctas.find((x) => isOnlineBanking(x));
  if (!onlineBanking) {
    return <></>;
  }

  const { url, cta, icon, badgeIcon } = onlineBanking;

  return (
    <>
      <WidgetActionButton
        label={cta}
        url={url}
        icon={icon}
        badgeIcon={badgeIcon}
        widgetName={widgetName}
      />
    </>
  );
};

export default OnlineBankWidget;
