import { styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { useEffect, useRef, useState } from 'react';
import { Typography } from '@neui/styleguide-commerzbank';
import shallow from 'zustand/shallow';

import { Chip } from '@components/neui-components/atoms/Chip';

import { ChatMessage } from './ChatMessage';
import { ChatFooter } from './ChatFooter';
import {
  ChatMessageType,
  selectChatActions,
  selectChatProps,
  useChatStore,
} from './store/store';
import { ChatTypingDots } from './ChatTypingDots';
import { apiClient } from './ChatWindow';
import { WarningRenderer } from './WarningRenderer';
import { MessageRenderer } from './MessageRenderer';
import { createUserMessage } from './utils/utils';

export function ChatScreen() {
  const [userInput, setUserInput] = useState<string>('');

  const {
    msgs,
    isChatMinimized,
    agentState,
    unsentMsgs,
    userState,
    isChatBotTyping,
    quickReplies,
    temporaryMsgs,
  } = useChatStore(selectChatProps, shallow);
  const { setUnreadMSgs, setQuickReplies, setTemporaryMsgs, setUnsentMsgs } =
    useChatStore(selectChatActions, shallow);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
    if (!isChatMinimized) {
      setUnreadMSgs(0);
    }
  }, [msgs, setUnreadMSgs]);

  const chatBodyRef = useRef<HTMLDivElement | null>(null);

  async function onSubmit(newUserMsg: ChatMessageType) {
    try {
      const res = await apiClient.chat.sendMessageUsingPost(
        {
          participantId: newUserMsg.participantId,
          message: newUserMsg.value,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            pToken: window?.ccb_cif?.pToken ?? '',
          },
        },
      );
      if (res.status !== 200) {
        throw new Error(`Couldnt send message, status ${res.status}`);
      }
    } catch (e) {
      setUnsentMsgs(unsentMsgs.concat(newUserMsg));
    }
  }

  const [chatBodyFocused, setChatBodyFocused] = useState(false);
  const updateChildrenTabIndex = (isFocused: boolean) => {
    if (chatBodyRef.current) {
      const focusableElements =
        chatBodyRef.current.querySelectorAll<HTMLElement>(
          'button, a, [tabindex]:not([tabindex="-1"])',
        );

      focusableElements.forEach((el) => {
        el.setAttribute('tabindex', isFocused ? '0' : '-1');
      });
    }
  };

  useEffect(() => {
    updateChildrenTabIndex(chatBodyFocused);
    if (chatBodyRef.current && chatBodyFocused) {
      const focusableElements =
        chatBodyRef.current.querySelectorAll<HTMLElement>(
          'button, a, [tabindex]:not([tabindex="-1"])',
        );

      if (focusableElements.length > 0) {
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        lastElement.focus();

        const handleTabKeyPress = (e: KeyboardEvent) => {
          if (e.code === 'Tab') {
            if (e.shiftKey && document.activeElement === firstElement) {
              e.preventDefault();
              lastElement.focus();
            } else if (!e.shiftKey && document.activeElement === lastElement) {
              e.preventDefault();
              firstElement.focus();
            }
          } else if (e.key === 'Escape') {
            chatBodyRef.current?.focus();
            setChatBodyFocused(false);
          }
        };
        chatBodyRef.current.addEventListener('keydown', handleTabKeyPress);
        return () => {
          chatBodyRef.current?.removeEventListener(
            'keydown',
            handleTabKeyPress,
          );
        };
      }
    }
  }, [chatBodyFocused]);

  useEffect(() => {
    if (!chatBodyRef.current) return;

    const observer = new MutationObserver(() => {
      updateChildrenTabIndex(chatBodyFocused);
    });

    observer.observe(chatBodyRef.current, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [chatBodyFocused]);

  const chatBodyKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (
      e.target === chatBodyRef.current &&
      (e.key === 'Enter' || e.key === 'NumpadEnter')
    ) {
      if (!chatBodyFocused) {
        e.preventDefault();
      }
      e.stopPropagation();
      setChatBodyFocused(true);
    }
  };

  return (
    <>
      <Container justifyContent={'center'}>
        <ChatBody tabIndex={0} ref={chatBodyRef} onKeyDown={chatBodyKeyDown}>
          <VStack spacing={4}>
            {msgs.map((msg, i) => {
              const shouldHideAuthor = msgs[i + 1]
                ? msgs[i + 1].participantId === msg.participantId
                : false;
              return (
                <>
                  <MessageRenderer
                    key={i}
                    shouldHideAuthor={shouldHideAuthor}
                    msg={msg}
                  />
                </>
              );
            })}
            {temporaryMsgs.map((tempMsg, i) => {
              const shouldHideAuthor = temporaryMsgs[i + 1]
                ? temporaryMsgs[i + 1].participantId === tempMsg.participantId
                : false;
              return (
                <>
                  <MessageRenderer
                    key={i}
                    shouldHideAuthor={shouldHideAuthor}
                    msg={tempMsg}
                  />
                </>
              );
            })}

            {isChatBotTyping && (
              <>
                <ChatMessage
                  participantId={''}
                  displayName={'Bot#Commerzbank ChatBot'}
                  shouldHideAuthor={true}
                  timestamp=""
                  sequenceNumber={0}
                >
                  <ChatTypingDots />
                </ChatMessage>
              </>
            )}
          </VStack>
          {quickReplies && quickReplies.length > 0 && (
            <HStack
              css={{ padding: '8px 16px', width: '100%' }}
              justifyContent={'center'}
              flexWrap={'wrap'}
              spacing={8}
            >
              {quickReplies.map((qr, i) => (
                <Chip
                  key={i}
                  onClick={async () => {
                    const displayName =
                      agentState.agents[userState.participantId];
                    const newUserMsg = createUserMessage({
                      userInput: qr.text,
                      participantId: userState.participantId,
                      displayName,
                      msgsLength: msgs.length,
                    });
                    setQuickReplies([]);
                    // setMsgs([...msgs, newUserMsg]);
                    setTemporaryMsgs([...temporaryMsgs, newUserMsg]);
                    await onSubmit(newUserMsg);
                  }}
                >
                  <Typography size={7} weight={'medium'}>
                    {qr.text}
                  </Typography>
                </Chip>
              ))}
            </HStack>
          )}
          {/* <ChatFeedback /> */}
          {/* <ChatMessage
            participantId="chatbot"
            timestamp={new Date().toString()}
            sequenceNumber={0}
          >
            <HStack spacing={48}>
              <VStack>
                <Headline type={'h6'} subline="PDF">
                  Chatverlauf_30.08.2023
                </Headline>
              </VStack>
              <IconLink
                icon={interaction___download}
                animationDirection="none"
                onClick={async () => {
                  const res = await apiClient.chat.messageHistoryUsingPost({
                    participantId: userState.participantId,
                  });

                  const msgsText = res.data.result?.data?.messages?.map(
                    (msg) => {
                      const date = new Date(msg.timestamp);
                      const formattedDate = date.toLocaleDateString();
                      const hrs = date.getHours();
                      const formattedHrs = ('0' + hrs).slice(-2);
                      const mins = date.getMinutes();
                      const formattedMins = ('0' + mins).slice(-2);
                      const time = formattedHrs + ':' + formattedMins;
                      return `${formattedDate} - ${time} - ${msg.participantId}: ${msg.value}`;
                    }
                  );
                  const pdfText = msgsText ? msgsText.join('\n') : '';
                  const doc = new jsPDF();
                  doc.text(pdfText, 24, 24);
                  doc.save('test');
                }}
              />
            </HStack>
          </ChatMessage> */}
        </ChatBody>
        <WarningRenderer />
      </Container>
      <ChatFooter
        userInput={userInput}
        setUserInput={setUserInput}
        onSubmit={onSubmit}
      />
    </>
  );
}

const ChatBody = styled(VStack, {
  gap: 4,
  top: 0,
  width: '100%',
  flex: '1 1 0px',
  overflowX: 'hidden',
  justifyContent: 'space-between',
  padding: '16px 16px 8px 16px',
  scrollBehavior: 'smooth',
  '&:hover, &:active, &:focus-visible': {
    overflowY: 'auto',
  },
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: -2,
  },
});

const Container = styled(VStack, {
  flex: '1 1 0',
  order: 2,
  position: 'relative',
  justifyContent: 'flex-start',
  '@sm': {
    height: 416,
  },
});
